import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { ProductSpecification } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as ProductSpecificationActions from './product-specification.actions'

export const productSpecificationsFeatureKey = 'productSpecifications'
export interface ProductSpecificationsState extends EntityState<DeepReadonly<ProductSpecification>> {
}

export const selectId = (productSpecification: ProductSpecification) => productSpecification.specification_id
export const adapter = createEntityAdapter<DeepReadonly<ProductSpecification>>({ selectId })

const initialState: ProductSpecificationsState = adapter.getInitialState({
})

export const productSpecificationsReducer = createReducer(
  initialState,
  on(ProductSpecificationActions.loadProductSpecificationsSuccess,
    (state, action) =>
      adapter.upsertMany(action.productSpecifications, state)),
  on(ProductSpecificationActions.createProductSpecificationSuccess,
    (state, action) =>
      adapter.upsertOne(action.productSpecification, state)),
  on(ProductSpecificationActions.updateProductSpecificationSuccess,
    (state, action) =>
      adapter.upsertOne(action.productSpecification, state)),
)

