import { Injectable } from '@angular/core'
import { Product } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { ModalService } from 'src/shared/modal/modal.service'
import { ProductFormComponent, ProductFormOptions } from './product-form.component'

@Injectable()
export class ProductFormService {

  constructor(private readonly modal: ModalService) { }

  showCreateProduct() {
    return this.show(null, { title: 'Create Product' })
  }

  showUpdateProduct(product: DeepReadonly<Product>) {
    return this.show(product, { title: 'Update Product' })
  }

  private show(product: DeepReadonly<Product>, options: ProductFormOptions) {
    return this.modal.openDialog<ProductFormComponent, ProductFormOptions, Product>(ProductFormComponent, { product, ...options }, {
      panelClass: ['tc-dialog', 'modal-lg'],
      width: null,
      maxWidth: null,
      autoFocus: false,
    }).toPromise()
  }
}
