<form [formGroup]="productForm">
  <h2 mat-dialog-title>
    <i class="fa fa-floppy-disk" aria-hidden="true"></i>&nbsp; {{title}}
  </h2>
  <div mat-dialog-content gdGap="15px">
    <div gdColumns="repeat(2, minmax(0, 1fr))" gdGap="15px">
      <mat-form-field gdColumn="span 2">
        <mat-label>Name</mat-label>
        <input matInput autocomplete="off" formControlName="name">
      </mat-form-field>
      <tc-select-search placeholder="Select a category in the list or search category name" [group]="productForm"
        ctrlName="category_id" [items]="categories$ | async" bindValue="category_id" (change)="categoryChanged()"
        bindLabel="name"></tc-select-search>
      <tc-select-search placeholder="Select a type in the list or search type name" [group]="productForm"
        ctrlName="type_id" [items]="selectableTypes$ | async" bindValue="type_id" bindLabel="name"></tc-select-search>

      <div *ngIf="productForm.controls.category_id.value === freightProductCategoryId">
        <mat-checkbox formControlName="unique_cost">
          Remove from Matched Offers
        </mat-checkbox>
      </div>
    </div>

    <div gdColumns="repeat(4, minmax(0, 1fr)) auto" gdGap="15px">
      <ng-container *ngFor="let item of productForm.controls.overrides.controls; index as i">
        <ng-container *ngIf="!item.controls?.deleted?.value">
          <tc-select-search placeholder="Select a company" [group]="item" ctrlName="account"
            [items]="selectableAccounts$ | async" bindValue="account" bindLabel="name"></tc-select-search>

          <tc-select-search placeholder="Select an item type" [group]="item" ctrlName="item_type_id"
            [items]="itemTypes$ | async" bindValue="item_type_id" bindLabel="name"></tc-select-search>

          <mat-form-field>
            <mat-label>Name override</mat-label>
            <input matInput type="text" autocomplete="off" [formControl]="item.controls.name">
          </mat-form-field>

          <mat-form-field>
            <mat-label>HS Code override</mat-label>
            <input matInput type="text" autocomplete="off"
              [formControl]="item.controls.hs_code">
          </mat-form-field>

          <i class="fa fa-minus-circle remove" *ngIf="!(inProgress$ | async)" (click)="markOverrideAsDeleted(item)"></i>
        </ng-container>
      </ng-container>
      <p *ngIf="!isLC" class="text-right" gdColumn="span 5">
        <button type="button" class="btn btn-link modal-table-add" (click)="addOverrideRow()"
          [disabled]="inProgress$ | async">+ Add Override
        </button>
      </p>
    </div>

    <div gdColumns="repeat(2, minmax(0, 1fr)) auto" gdGap="15px">
      <ng-container *ngFor="let item of productForm.controls.schedule_b_codes.controls; index as i">
        <tc-select-search placeholder="Select an item type" [group]="item" ctrlName="item_type_id"
          [items]="itemTypes$ | async" bindValue="item_type_id" bindLabel="name"></tc-select-search>

        <mat-form-field>
          <mat-label>Schedule B code</mat-label>
          <input matInput type="text" autocomplete="off" [formControl]="item.controls.code">
        </mat-form-field>

        <i class="fa fa-minus-circle remove" *ngIf="!(inProgress$ | async)" (click)="deleteScheduleBCode(i)"></i>
      </ng-container>
      <p class="text-right" gdColumn="span 3">
        <button type="button" class="btn btn-link modal-table-add" (click)="addScheduleBCodeRow()" [disabled]="inProgress$ | async">+ Add Schedule B code
        </button>
      </p>
    </div>
    <mat-checkbox [formControl]="productForm.controls.so_creation">
      Enable Supplier Offer Creation
    </mat-checkbox>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" mat-dialog-close>Cancel</button>
    <button mat-raised-button type="button" color="primary" (click)="save()" [disabled]="inProgress$ | async">
      <i class="fa fa-save"></i>&nbsp; {{productForm.controls.product_id.value ? 'Update' : 'Save'}}
    </button>
  </div>
</form>
