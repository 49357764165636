import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { AccountObject, AccountProductSpec, Product, ScheduleBCode } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { map as _map, cloneDeep, compact, differenceBy, filter, findIndex, flatten, groupBy, identity, isEmpty, isEqual, omit, orderBy, pick, pickBy, reject, uniq, xorWith } from 'lodash-es'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { distinctUntilChanged, map, take } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadAccounts, selectAccountEntities, selectAllAccounts } from 'src/app/store/accounts'
import { loadItemTypes, selectAllItemTypes } from 'src/app/store/item-types'
import { loadProductCategories, selectAllProductCategories } from 'src/app/store/product-categories'
import { loadProductTypes, selectAllProductTypes } from 'src/app/store/product-types'
import { updateProductSuccess } from 'src/app/store/products'
import { environment } from 'src/environments/environment'
import { AccountsService } from 'src/services/data/accounts.service'
import { ProductsService } from 'src/services/data/products.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'

export interface ProductFormOptions {
  title?: string,
  product?: DeepReadonly<Product>,
}

@Component({
  selector: 'tc-product-form',
  styleUrls: ['./product-form.component.scss'],
  templateUrl: './product-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private readonly toaster: ToasterService,
    private readonly Products: ProductsService,
    private readonly Accounts: AccountsService,
    private readonly store: Store,
    private readonly dialogRef: MatDialogRef<ProductFormComponent, Product>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: ProductFormOptions,
    private readonly AuthApi: AuthApiService,
  ) {
    super()
  }

  // const
  protected readonly freightProductCategoryId = environment.freightProductCategoryId

  // options
  protected readonly title = this.dialogData?.title || 'Product type'
  protected readonly isLC = this.AuthApi.currentUser.role === 'logistics'

  // state
  protected readonly inProgress$ = new BehaviorSubject<'loading' | 'save' | undefined>('loading');
  private overridesSnapshot: DeepReadonly<ReturnType<ReturnType<typeof this.generateOverrideRowForm>['getRawValue']>[]>
  protected readonly productForm = new FormGroup({
    product_id: new FormControl(this.dialogData.product?.product_id),
    name: new FormControl(this.dialogData.product?.name, Validators.required),
    category_id: new FormControl(this.dialogData.product?.category_id, Validators.required),
    type_id: new FormControl(this.dialogData.product?.type_id, Validators.required),
    unique_cost: new FormControl(this.dialogData.product?.unique_cost || false),
    schedule_b_codes: new FormArray(
      this.dialogData.product?.schedule_b_codes?.map(this.generateScheduleBRowForm) || []),
    overrides: new FormArray<ReturnType<typeof this.generateOverrideRowForm>>([]),
    so_creation: new FormControl(this.dialogData.product?.so_creation || false),
  });


  // ref data
  private readonly accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  protected readonly itemTypes$ = this.store.pipe(select(selectAllItemTypes), waitNotEmpty())
  protected readonly categories$ = this.store.pipe(select(selectAllProductCategories), waitNotEmpty())
  protected readonly selectableAccounts$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty(), map((accounts) => orderBy(accounts, 'name')))
  protected readonly selectableTypes$ = combineLatest([
    this.store.pipe(select(selectAllProductTypes), waitNotEmpty()),
    replayForm(this.productForm.controls.category_id).pipe(distinctUntilChanged()),
  ]).pipe(map(([productTypes, categoryId]) => productTypes?.filter(x => x.category_id === categoryId)))


  ngOnInit(): void {
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadItemTypes())
    this.store.dispatch(loadProductCategories())
    this.store.dispatch(loadProductTypes())

    this.accounts$.pipe(take(1)).subscribe(async (companies) => {
      const overrides = await this.populateOverrideFields(companies, this.dialogData.product?.product_id);
      this.productForm.controls.overrides.clear();
      overrides?.forEach(override => this.productForm.controls.overrides.push(override));
      this.overridesSnapshot = cloneDeep(this.productForm.controls.overrides.getRawValue())
      this.inProgress$.next(undefined)
    });

    this.inProgress$.subscribe((inProgress) => {
      if (inProgress) this.productForm.disable();
      else this.productForm.enable();
      if (this.AuthApi.currentUser.role === 'logistics') {
        this.productForm.controls.name.disable()
        this.productForm.controls.category_id.disable()
        this.productForm.controls.type_id.disable()
        this.productForm.controls.so_creation.disable()
      }
    })
  }

  private async populateOverrideFields(_companies: unknown, product_id: string) {
    if (!product_id) return []
    // TODO: safeguard code/api. use companies after WA-5591 passed QA at prod
    let companies = await this.Accounts.getAccountsByProduct(product_id)
    companies = await this.Accounts.getAccountsProducts(companies.map(c => c.account))
    return flatten(compact(companies?.map((company) => {
      const overrides = filter(company.products_spec, { product_id }).filter((override) => this.isOverrideVisible(override))
      if (!overrides.length) return false
      return overrides.map(override => this.generateOverrideRowForm(override, company.account))
    })))
  }

  protected categoryChanged() {
    this.productForm.controls.type_id.setValue(null);
    this.productForm.controls.unique_cost.setValue(false);
  }

  protected addOverrideRow(): void {
    this.productForm.controls.overrides.push(this.generateOverrideRowForm());
  }

  protected markOverrideAsDeleted(control: FormGroup): void {
    if(!this.inProgress$.value) {
      control.get('deleted').setValue(true);
    }
  }

  protected addScheduleBCodeRow(): void {
    this.productForm.controls.schedule_b_codes.push(this.generateScheduleBRowForm());
  }

  protected deleteScheduleBCode(index: number): void {
    if(!this.inProgress$.value) {
      this.productForm.controls.schedule_b_codes.removeAt(index);
    }
  }

  protected async save() {
    if (this.inProgress$.value) return

    this.productForm.markAllAsTouched()
    this.productForm.updateValueAndValidity()
    if (!this.productForm.valid) return;

    try {
      this.inProgress$.next('save');

      const productData = {
        ...(this.dialogData?.product || {}),
        ...omit(this.productForm?.getRawValue(), ['overrides']),
      }

      const product = productData.product_id
        ? await this.Products.update(productData)
        : await this.Products.create(productData)

      const productOverrides = this.productForm?.getRawValue()?.overrides;
      this.accounts$.pipe(take(1)).subscribe(async (companies) => {
        if (!this.isArrayEqual(productOverrides, this.overridesSnapshot)) {
          const patches = this.generateOverridesPatchPayload(companies, product, productOverrides, this.overridesSnapshot)
          await Promise.all(patches.map(({ company, products_spec }) => this.Accounts.updateAccountProducts(company, products_spec)))
        }
        this.store.dispatch(updateProductSuccess({ product }))
        this.toaster.success('Product saved successfully')
        this.dialogRef.close(product)
      })
    } catch (e) {
      console.error('Unable to save product', e)
      this.toaster.error('Unable to save product', e)
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  private generateOverridesPatchPayload(
    companies: DeepReadonly<Dictionary<AccountObject>>,
    product: DeepReadonly<Product>,
    nextOverrides: DeepReadonly<ReturnType<ReturnType<typeof this.generateOverrideRowForm>['getRawValue']>[]>,
    prevOverrides: DeepReadonly<ReturnType<ReturnType<typeof this.generateOverrideRowForm>['getRawValue']>[]>,
  ) {
    const product_id = product.product_id
    const nextPatches = compact(_map(groupBy(nextOverrides, 'account'), (productOverridesForm, account) => {
      const company = companies[account]
      const previous = filter(company.products_spec, { product_id })
      const productOverrides = productOverridesForm.map(x => ({
        ...omit(x, 'account'),
        item_type_id: x.item_type_id || undefined,
        product_id,
      }))

      if (this.isArrayEqual(previous, productOverrides)) return false
      const otherProductsProfiles = reject(company.products_spec, { product_id })
      const updated = reject(productOverrides, 'deleted').map(x => pickBy(omit(x, 'deleted'), identity) as Omit<typeof x, 'deleted'>)
      const invisible = reject(previous, (override => this.isOverrideVisible(override))) // they don't override
      // const deleted = filter(overrides, 'deleted')
      updated.forEach((override) => {
        const idx = findIndex(invisible, pick(override, ['product_id', 'item_type_id']))
        if (idx === -1) return
        invisible.splice(idx, 1)
        // // safeguard code. we don't want users to remove products from company profile. not here.
        // override.profile = override.profile || previouslyInvisible.profile
      })
      const products_spec = [
        ...otherProductsProfiles,
        ...invisible,
        ...updated,
      ]
      return { company, products_spec }
    }))

    // handle companies whos overrides were reassigned to other companies
    const removedCompanies = compact(uniq(differenceBy(prevOverrides, nextOverrides, 'account').map(o => companies[o.account])))
    const prevPatches = removedCompanies.map((company) => ({
      company,
      products_spec: reject(company.products_spec, { product_id }),
    }))
    return [...nextPatches, ...prevPatches]
  }

  private generateOverrideRowForm(override?: DeepReadonly<AccountProductSpec>, account?: number) {
    return new FormGroup({
      item_type_id: new FormControl(override?.item_type_id),
      account: new FormControl(account, Validators.required),
      name: new FormControl(override?.name),
      hs_code: new FormControl(override?.hs_code),
      deleted: new FormControl(false),
    })
  }

  private generateScheduleBRowForm(scheduleBCode?: ScheduleBCode) {
    return new FormGroup({
      item_type_id: new FormControl(scheduleBCode?.item_type_id),
      code: new FormControl(scheduleBCode?.code, Validators.required),
    })
  }

  private isArrayEqual<T>(x: DeepReadonly<T[]>, y: DeepReadonly<T[]>): boolean {
    return isEmpty(xorWith(x, y, isEqual));
  }

  private isOverrideVisible(x: DeepReadonly<AccountProductSpec>): boolean {
    return !!x.name || !!x.hs_code;
  }
}

